export const home: string = "";
export const login: string = "login";
export const register: string = "register";
export const profile: string = "profile";
export const catalog: string = "catalog";
export const orders: string = "orders";
export const chats: string = "chats";
export const settings: string = "settings";
export const permissions: string = "permissions";
export const support: string = "support";
export const forgotPassword: string = "reset_password";

export const notFound = '404';