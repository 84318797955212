export const FETCH_SELLER_ORDERS_REQUEST = 'FETCH_SELLER_ORDERS_REQUEST';
export const FETCH_SELLER_ORDERS_SUCCESS = 'FETCH_SELLER_ORDERS_SUCCESS';
export const FETCH_SELLER_ORDERS_FAILURE = 'FETCH_SELLER_ORDERS_FAILURE';

export const UPDATE_ORDER_STATUS_REQUEST = 'UPDATE_ORDER_STATUS_REQUEST';
export const UPDATE_ORDER_STATUS_SUCCESS = 'UPDATE_ORDER_STATUS_SUCCESS';
export const UPDATE_ORDER_STATUS_FAILURE = 'UPDATE_ORDER_STATUS_FAILURE';

export const FETCH_ORDER_DETAILS_REQUEST = 'FETCH_ORDER_DETAILS_REQUEST';
export const FETCH_ORDER_DETAILS_SUCCESS = 'FETCH_ORDER_DETAILS_SUCCESS';
export const FETCH_ORDER_DETAILS_FAILURE = 'FETCH_ORDER_DETAILS_FAILURE';